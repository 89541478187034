<script setup lang="ts">
const buttonColors = ref<('primary' | 'secondary' | 'tertiary' | 'delete' | 'disabled')[]>([
  'primary',
  'secondary',
  'tertiary',
  'delete',
  'disabled',
]);
</script>
<template>
  <div class="grid gap-4">
    <ui-card v-for="color in buttonColors" :key="color" :title="color[0].toUpperCase() + color.slice(1)">
      <label class="text-sm text-gray-600 font-medium block mb-2">{{ $t('component-library.classic_buttons') }}</label>
      <div class="flex items-center flex-wrap gap-4">
        <ui-button size="xl" :color="color">Extra large</ui-button>
        <ui-button size="lg" :color="color">Large</ui-button>
        <ui-button size="md" :color="color">Medium</ui-button>
        <ui-button size="sm" :color="color">Small</ui-button>
        <ui-button size="xs" :color="color">Extra small</ui-button>
      </div>

      <hr class="my-4" />

      <label class="text-sm text-gray-600 font-medium block mb-2">{{ $t('component-library.left_icon_buttons') }}</label>
      <div class="flex items-center flex-wrap gap-4">
        <ui-button left-icon="Plus" size="xl" :color="color">Extra large</ui-button>
        <ui-button left-icon="Plus" size="lg" :color="color">Large</ui-button>
        <ui-button left-icon="Plus" size="md" :color="color">Medium</ui-button>
        <ui-button left-icon="Plus" size="sm" :color="color">Small</ui-button>
        <ui-button left-icon="Plus" size="xs" :color="color">Extra small</ui-button>
      </div>

      <hr class="my-4" />

      <label class="text-sm text-gray-600 font-medium block mb-2">{{ $t('component-library.right_icon_buttons') }}</label>
      <div class="flex items-center flex-wrap gap-4">
        <ui-button right-icon="ArrowRight" size="xl" :color="color">Extra large</ui-button>
        <ui-button right-icon="ArrowRight" size="lg" :color="color">Large</ui-button>
        <ui-button right-icon="ArrowRight" size="md" :color="color">Medium</ui-button>
        <ui-button right-icon="ArrowRight" size="sm" :color="color">Small</ui-button>
        <ui-button right-icon="ArrowRight" size="xs" :color="color">Extra small</ui-button>
      </div>

      <hr class="my-4" />

      <label class="text-sm text-gray-600 font-medium block mb-2">{{ $t('component-library.button_with_loader') }}</label>
      <div class="flex items-center flex-wrap gap-4">
        <ui-button size="xl" loading :color="color">Extra large</ui-button>
        <ui-button size="lg" loading :color="color">Large</ui-button>
        <ui-button size="md" loading :color="color">Medium</ui-button>
        <ui-button size="sm" loading :color="color">Small</ui-button>
        <ui-button size="xs" loading :color="color">Extra small</ui-button>
      </div>
    </ui-card>
  </div>
</template>
